<script setup lang="ts">
import type { ModelRef } from 'vue';
import {
  getCalendarDaysWithFullWeeks,
  WEEK_DAYS_SHORT,
  createClassNameByIndex,
  isCurrentMonth,
  isCurrentDate,
  getWeekNumber,
} from '../utils/index';

const props = defineProps<{
  type: 'multiple' | 'range' | 'single';
}>();

const emit = defineEmits<{
  (
    e: 'onSelectEndDate',
    dates: {
      startDate: Date;
      endDate: Date;
    },
  ): void;
  (e: 'onSelectMultipleDate', date: Date): void;
}>();

const currentDate: ModelRef<string> = defineModel('currentDate', {
  type: String,
  required: false,
  default: new Date().toISOString(),
});

const selectedDatesRanges: ModelRef<
  {
    startDate: Date;
    endDate: Date | undefined;
  }[]
> = defineModel('selectedDatesRanges', {
  type: Array as () => {
    startDate: Date;
    endDate: Date;
  }[],
  required: false,
  default: [],
});

const selectedDates: ModelRef<Date[]> = defineModel('selectedDates', {
  type: Array as () => Date[],
  required: false,
  default: [],
});

const month = computed(() =>
  getCalendarDaysWithFullWeeks(new Date(currentDate.value)),
);

function selectDate(date: Date) {
  if (props.type === 'single') {
    currentDate.value = date.toISOString();

    return;
  }

  if (props.type === 'range' && selectedDatesRanges.value) {
    const duplicatedStartDate = selectedDatesRanges.value.find(
      (selectedDate) => selectedDate.startDate.getTime() === date.getTime(),
    );

    if (duplicatedStartDate) {
      selectedDatesRanges.value = selectedDatesRanges.value.filter(
        (selectedDate) => selectedDate.startDate.getTime() !== date.getTime(),
      );

      return;
    }

    const emptyEndDate = selectedDatesRanges.value.find(
      (date) => date.endDate === undefined,
    );

    if (emptyEndDate) {
      emptyEndDate.endDate = date;

      emit('onSelectEndDate', {
        startDate: emptyEndDate.startDate,
        endDate: date,
      });
    } else {
      selectedDatesRanges.value.push({
        startDate: date,
        endDate: undefined,
      });
    }

    return;
  }

  if (props.type === 'multiple') {
    if (selectedDates.value.includes(date)) {
      selectedDates.value = selectedDates.value.filter(
        (selectedDate) => selectedDate.getTime() !== date.getTime(),
      );

      return;
    }

    selectedDates.value = [...selectedDates.value, date];

    emit('onSelectMultipleDate', date);
  }
}

function isSameDay(date1: Date, date2: Date) {
  return (
    date1.getFullYear() === date2.getFullYear() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getDate() === date2.getDate()
  );
}
</script>

<template>
  <div class="grid grid-cols-7 justify-items-center">
    <div :key="day" v-for="(day, index) in WEEK_DAYS_SHORT">
      <slot name="weekday" :day="day" :index="index" />
    </div>

    <div
      :key="index"
      v-for="(date, index) in month"
      class="flex w-full flex-col items-center justify-center"
      :class="createClassNameByIndex(index)"
    >
      <div class="flex h-full w-full" @click="selectDate(date)">
        <slot
          name="button"
          :date="date"
          :isCurrentMonth="isCurrentMonth(date, new Date(currentDate))"
          :isCurrentDate="isCurrentDate(date, new Date(currentDate))"
          :isStartDate="
            selectedDatesRanges?.some((selectedDate) =>
              isSameDay(selectedDate.startDate, date),
            )
          "
          :isEndDate="
            selectedDatesRanges?.some(
              (selectedDate) =>
                selectedDate.endDate && isSameDay(selectedDate.endDate, date),
            )
          "
          :isMiddleDate="
            selectedDatesRanges?.some(
              (selectedDate) =>
                selectedDate.startDate.getTime() < date.getTime() &&
                selectedDate.endDate &&
                selectedDate.endDate.getTime() > date.getTime(),
            )
          "
          :isSelected="
            selectedDates?.some((selectedDate) => isSameDay(selectedDate, date))
          "
        />
      </div>

      <slot name="message" :date="date"></slot>
    </div>
  </div>
</template>
