<script setup lang="ts">
import CalendarMonth from '@/calendar/components/CalendarMonth.vue';

import {
  YEARS,
  MONTHS,
  getChangedYearDate,
  getChangedMonthDate,
} from '~/calendar/utils';

const currentDate = defineModel('currentDate', {
  type: String,
  required: true,
});

function changeMonth(month: number) {
  currentDate.value = getChangedMonthDate(
    month,
    currentDate.value,
  ).toISOString();
}

function changeYear(year: number) {
  currentDate.value = getChangedYearDate(year, currentDate.value).toISOString();
}
</script>

<template>
  <div class="space-y-6">
    <div class="flex justify-center gap-4">
      <PrimeSelect
        class="font-semibold"
        :options="MONTHS"
        optionLabel="name"
        optionValue="value"
        :modelValue="new Date(currentDate).getMonth()"
        @update:modelValue="changeMonth"
      />

      <PrimeSelect
        class="font-semibold"
        :options="YEARS"
        :modelValue="new Date(currentDate).getFullYear()"
        @update:modelValue="changeYear"
      />
    </div>

    <CalendarMonth v-model:current-date="currentDate" type="single">
      <template #weekday="{ day, index }">
        <div
          class="text-sm font-medium"
          :class="{
            'text-gray-iron-500': [5, 6].includes(index),
            'text-gray-iron-900': ![5, 6].includes(index),
          }"
        >
          {{ day }}
        </div>
      </template>

      <template #button="{ date, isCurrentMonth, isCurrentDate }">
        <PrimeButton
          v-if="!isCurrentMonth"
          class="mx-auto block"
          text
          severity="secondary"
          disabled
          rounded
        >
          {{ date.getDate() }}
        </PrimeButton>

        <PrimeButton
          v-else-if="isCurrentDate"
          class="mx-auto block bg-primary-50 font-semibold"
          text
          severity="primary"
          rounded
        >
          {{ date.getDate() }}
        </PrimeButton>

        <PrimeButton
          v-else
          class="mx-auto block"
          text
          severity="secondary"
          rounded
        >
          {{ date.getDate() }}
        </PrimeButton>
      </template>

      <template #message="{ date }">
        <slot name="message" :date="date" />
      </template>
    </CalendarMonth>
  </div>
</template>
